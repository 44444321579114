
.email-textarea {
  width: 100%;
  height: 50em;
  outline: none;
  border-width: 1px;
  border-color: hsl(0, 0%, 70%);
  border-style: solid;
  border-radius: 0.25em;
  font-family: inherit;
  font-size: 1em;
}

.email-textarea:focus {
  border-color: hsl(200, 100%, 50%);
  box-shadow: 0 0 5px 0 hsl(200, 100%, 40%);
}

.email-add-remove-btn-margin {
  margin-left: 0.5em;
}

.email-add-recipient-select {
  outline: none;
  border-width: 1px;
  border-color: hsl(0, 0%, 70%);
  border-style: solid;
  border-radius: 0.25em;
  font-family: inherit;
  font-size: 1.1em;
  color: hsl(0, 0%, 30%);
}

.email-add-recipient-select:focus {
  border-color: hsl(200, 100%, 50%);
  box-shadow: 0 0 5px 0 hsl(200, 100%, 40%);
}