.slct {
  border-width: 1px;
  border-radius: 0.1em;
  font-family: inherit;
  font-size: 1em;
  border-color: hsl(200, 100%, 30%);
  padding: 0.5em 0.5;
  text-align-last:center;
}

.slct-large {
  font-size: 1.25em;
}

.fixed-width {
  font-family: 'Courier New', Courier, monospace;
}