.roster-summary-container-div {
  display: flex;
  width: auto;
  align-items: stretch;
  padding: 0;
  margin: 0.25rem;
}

.roster-summary-div {
  display: inline-block;
  width: 100%;
  padding: 0;
  margin: 25px;
  align-self: flex-end;
}