.btn {
    border-width: 1px;
    border-radius: 0.1em;
    color: white;
    font-family: inherit;
    font-size: 1em;
    background-color: hsl(200, 100%, 50%);
    border-color: hsl(200, 100%, 30%);
    padding: 0.5em 2em;
}

.btn:hover, .btn:focus {
    cursor: pointer;
    background-color: hsl(200, 100%, 60%);
}

.btn:active {
    background-color: hsl(200, 100%, 40%);
}

.btn:disabled {
    cursor:not-allowed;
    color:hsl(0, 0%, 30%);
    background-color: hsl(200, 10%, 70%);
}

.btn.btn-add {
    padding: 0.25em 0.5em;
    font-weight: bold;
}

.btn.btn-add:hover, .btn.btn-add:focus {
    background-color: hsl(200, 100%, 60%);
}

.btn.btn-add:active {
    background-color: hsl(200, 100%, 40%);
}

.btn.btn-cancel {
    background-color: hsl(0, 80%, 50%);
    margin-right: 10px;
}

.btn.btn-cancel:hover, .btn.btn-cancel:focus {
    background-color: hsl(0, 100%, 60%);
}

.btn.btn-cancel:active {
    background-color: hsl(0, 100%, 40%);
}
