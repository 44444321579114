

.dynamictable-container-div {
  display: inline-block;
  width: 100%;
}

.dynamictable-table-container-div {
  width: 100%;
}

.dynamictable-table {
  padding: 0;
  border-spacing: 1px;
  width: 100%;
  
  border-collapse: collapse;
  border-radius: 0.5em;
  overflow: hidden;
  box-shadow: 0 0 10px 0 hsl(200, 0%, 40%);
}

.dynamictable-header-row {
  padding: 0;
  margin: 0;
}

.dynamictable-header-val {
  color: white;
  background-color: #36304a;
  margin: 0;
  border: 0;
  font-size: 1.25rem;
  padding: 0.25em 1em;
}

.dynamictable-row:nth-child(odd) {
  background-color: hsl(200, 0%, 95%);
}

.dynamictable-row:nth-child(even) {
  background-color: hsl(200, 0%, 90%);
}

.dynamictable-row:hover {
  background-color: hsl(200, 0%, 80%);
}

.dynamictable-row-val {
  padding: 0.1em 0.5em;
  text-align: center;
  color: #555;
}

.dynamictable-info-div {
  text-align: right;
}

.dynamictable-info-txt {
  margin: 0;
  font-size: 1.25rem;
}

.dynamictable-clickable-row:hover {
  cursor: pointer;
}