
.admin-content-section {
  margin: 1em 1em;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.admin-container {
  width: 100%;
  border: 0;
  border-radius: 4px;
  box-shadow: 0 0 5px 0 hsl(0, 0%, 60%);
  padding: 1em;
  height: fit-content;
  margin: 1em;
}

.admin-container-narrow {
  width: 500px;
  border: 0;
  border-radius: 4px;
  box-shadow: 0 0 5px 0 hsl(0, 0%, 60%);
  padding: 1em;
  height: fit-content;
  margin: 1em;
}

.admin-container-medium {
  width: 800px;
  border: 0;
  border-radius: 4px;
  box-shadow: 0 0 5px 0 hsl(0, 0%, 60%);
  padding: 1em;
  height: fit-content;
  margin: 1em;
}

.admin-container-fill {
  width: 100%;
  border: 0;
  border-radius: 4px;
  box-shadow: 0 0 5px 0 hsl(0, 0%, 60%);
  padding: 1em;
  height: fit-content;
  margin: 1em;
}

.admin-row {
  padding-top: 1rem;
  padding-bottom: 0.2em;
}

.admin-container-narrow-no-shadow {
  width: 500px;
  border: 0;
  padding: 1em;
  height: fit-content;
  margin: 1em;
}