.fileinput-container {
  display: flex;
  width: auto;
  align-items: center;
  padding: 0;
  margin: 0.25rem;
  justify-content: center;
}

.fileinput-div {
  display: inline-block;
  width: 10em;
}

.fileinput-label {
  padding: 0.5em;
  border: 1px solid black;
  margin: 0;
}

.fileinput-input {
  display: none;
}

.fileinput-text {
  flex-grow: 1;
  padding: 0.5em;
  margin: 0;
  color: hsl(0, 0%, 40%);
}

.fileinput-filename-box {
  width: 100%;
}