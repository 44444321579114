.datecardeventitem-container-div {
  grid-area: detail;
  padding: 20px 80px 32px 32px;
  background-color: #fff;
  box-sizing: border-box;
  width: 100%;
}

.datecardeventitem-h1 {
  background: #e9e9e9;
  color: #000;
  padding: 10px;
  border-radius: 5px;
  font-size: 22px;
  width: 100%;
}

.datecardeventitem-h3 {
  margin: 0;
  padding: 0;
  font-size: 20px;
  margin-bottom: 10px;
}

.datecardeventitem-p {
  margin: 10px 0 0;
  padding: 0;
  font-size: 16px;
}

.datecardeveentitem-a {
  text-decoration: none;
  font-size: 16px;
}

.datecardeventitem-a:hover {
  color: #479774;
}

.datecardeventitem-table {
  width: 100%;
}

.datecardeventitem-table tr td {
  padding: 5px 5px;
}

.datecardeventitem-div {
}

.datecardeventitem-notonroster {
  color: #CC3333;
}