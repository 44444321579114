.adminnav-container {
  height: 100%;
  float: left;
  margin-left: 1em;
  width: 180px;
}

.adminnav-navlink-div {
  font-size: 1.25em;
  font-weight: bold;
  margin-bottom: 0.5em;
}

.adminnav-top-container {
  width: 100%;
  margin-bottom: 1em;
  height: 1em;
  text-align: center;
}

.adminnav-top-navlink-div {
  display: inline-block;
  font-size: 1.25em;
  font-weight: bold;
  margin-right: 0.5em;
}