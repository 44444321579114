.user-roster-content-section {
  margin: 1em 1em;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.user-roster-div {
  width: 500px;
  border: 0;
  border-radius: 4px;
  padding: 1em;
  height: fit-content;
  margin: 1em;
}