.datecard-li {
  background-color: #fff;
  box-sizing: border-box;
  display: flex;
  box-shadow: 0 0 10px 0px #a1a1a15e;
  border-radius: 10px;
  width: 90%;
  margin: 5%;
  margin-bottom: 20px;
}

.datecard-time-div {
  position: relative;
  padding: 15px;
  background-color: #666c98;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s;
  text-align: center;
  border-radius: 10px;
}

.datecard-time-h2 {
  margin: 0;
  padding: 0;
  color: #fff;
  font-size: 60px;
  line-height: 30px;
}

.datecard-time-span {
  font-size: 20px;
}
