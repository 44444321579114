@import "SideNav.css";
@import "Input.css";
@import "Button.css";
@import "SignIn.css";
@import "DynamicTable.css";
@import "FileInput.css";
@import "DateCard.css";
@import "DateCardEventItem.css";
@import "Admin.css";
@import "AdminNav.css";
@import "Email.css";
@import "Event.css";
@import "Select.css";
@import "UserHome.css";
@import "Rosters.css";
@import "UserRoster.css";
@import "../../../node_modules/react-datepicker/dist/react-datepicker.css";

* {
    box-sizing: border-box;
}

body {
    margin: 0;
    color: hsl(0, 0%, 30%);
    font-size: 1.1em;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 500;
}

.div-right {
    text-align: right;
}

.div-center {
    text-align: center;
}

.div-flex-center {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.div-inline-block {
    display: inline-block;
}

.div-inline-block-full {
    display: inline-block;
    width: 100%;
}

.error {
    color: red;
}

.full-width {
    width: 100%;
}

.div-loader {
    margin-top: 15%;
}

.half-width {
    display: inline-block;
    width: 50%;
}