.userhome-section {
  width: 100%;
  display: block;
  margin: auto;
  font-size: 1.25em;
}

.userhome-events-div {
  grid-area: event;
  background-color: rgba(245, 245, 245, 0.5);
  box-sizing: border-box;
  min-width: 600px;
}

.userhome-events-ul {
  margin: 0;
  padding: 20px;
  list-style: none;
}

.userhome-roster-link-div {
  text-align: center;
  width: 100%;
  font-size: 1.1em;
}
