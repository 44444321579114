.event-info-div {
  width: 500px;
  font-size: 1.25em;
}

.event-info-label-span {
  text-align: left;
}

.event-info-value-span {
  text-align: right;
  display: block;
}

.event-roster-info-div {
  text-align: center;
  padding-top: 2em;
  padding-bottom: 0.5em;
}

.event-roster-info-span {
  padding: 0em 0.5em;
}
