.inpt {
    width: 100%;
    height: 2.5rem;
    outline: none;
    border-width: 1px;
    border-color: hsl(0, 0%, 70%);
    border-style: solid;
    font-family: inherit;
    font-size: 1em;
    padding-left: 0.5em;
    border-radius: 0.25em;
}

.inpt:focus {
    border-color: hsl(200, 100%, 50%);
    box-shadow: 0 0 5px 0 hsl(200, 100%, 40%);
}

.inpt:disabled {
    background-color: hsl(200, 20%, 90%);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}