.signin-root-div {
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
    margin-top: 10%;
}

.signin-container {
    max-width: 500px;
    border: 0;
    border-radius: 4px;
    box-shadow: 0 0 5px 0 hsl(0, 0%, 60%);
    padding: 1em;
    height: fit-content;
}

.signin-img-div {
    text-align: center;
    padding: 1em;
}

.signin-img-div img {
    width: 50%;
}

.signin-label {
}

.signin-row {
    padding-top: 1rem;
    padding-bottom: 0.2em;
}
